import { css } from '@emotion/react'

import FrontBg from '../../images/field_notes/front_bg.jpg'
import BgStory1 from '../../images/field_notes/bgstory_1.jpg'
import BgTimeline1 from '../../images/field_notes/bgtimeline_1.jpg'
import BgStory2 from '../../images/field_notes/bgstory_2.jpg'
import BgTimeline2 from '../../images/field_notes/bgtimeline_2.jpg'
import BgStory3 from '../../images/field_notes/bgstory_3.jpg'
import BgTimeline3 from '../../images/field_notes/bgtimeline_3.jpg'
import BgStory4 from '../../images/field_notes/bgstory_4.jpg'
import BgTimeline4 from '../../images/field_notes/bgtimeline_4.jpg'
import BgStory5 from '../../images/field_notes/bgstory_5.jpg'
import BgTimeline5 from '../../images/field_notes/bgtimeline_5.jpg'
import BgStory6 from '../../images/field_notes/bgstory_6.jpg'
import BgTimeline6 from '../../images/field_notes/bgtimeline_6.jpg'

import { scrollingStyles } from './ptt-styles'

const timelineStyles = css`
  ${scrollingStyles}

  margin: 0 auto;
  max-width: 1440px;

  & {
    .main_header {
      background-image: url(${FrontBg});
    }

    .header_text {
      margin: 10vh 0 3vh;

      span {
        font-size: 1.3rem;
      }
    }

    .slick-list {
      .slick-track {
        box-shadow: none;
        height: auto !important;
      }

      img {
        margin: 0 auto;
        max-height: 75vh;
        max-width: 100vw;
      }
    }

    .slick-slider {
      padding-bottom: 50px;
    }

    .slick-list {
      .slick-track {
        box-shadow: none;
        height: auto !important;
      }

      img {
        margin: 0 auto;
        max-height: 75vh;
        max-width: 100%;
      }
    }

    .slick-prev,
    .slick-next {
      height: 32px;
      width: 32px;
      z-index: 1;

      &::before {
        color: #000;
        font-size: 32px;
      }
    }

    .slick-prev {
      left: 10px;
    }

    .slick-next {
      right: 10px;
    }

    .slick-dots {
      bottom: 25px;
    }
  }

  #how-we-start {
    margin: 0 auto;
    max-width: 800px;
    padding: 0 10px;
  }

  & {
    p {
      font-size: 1.125rem;
      font-weight: 400;
      padding-bottom: 10px;
      text-align: center;
    }
  }

  #how-we-start {
    ul {
      font-size: 1.125rem;
      font-weight: 400;
      text-align: left;
    }
  }
  @media screen and (min-width: 760px) {
    #how-we-start {
      ul {
        padding: 0 0 50px 180px;
      }
    }
  }

  & {
    .grey {
      background-color: #939597;
      padding-bottom: 0;

      ul {
        color: #fff;
        font-size: 1.125rem;
        font-weight: 100;
        height: 100%;
        list-style: none;
        margin: 1em 0 0;
        padding: 0;
      }

      .col {
        padding: 0 20px;
      }

      li {
        + li {
          padding-top: 1em;
        }
      }
    }
  }
  @media screen and (min-width: 500px) {
    .grey {
      padding-bottom: 60px;

      .col {
        padding: 0 46px 0 10px;

        + .col {
          border-left: 1px solid #fff;
          padding: 0 10px 0 46px;
        }
      }
    }
  }

  & {
    .initial {
      .play-video {
        display: block;
        margin: 0 auto;
        max-width: 800px;
        position: relative;

        img {
          display: block;
          height: auto;
          width: 100%;
        }
      }
    }
  }
  @media screen and (min-width: 500px) {
    .initial {
      padding: 40px 0;
    }
  }

  .initial-video {
    max-width: 800px;
    width: 100%;
  }

  .columns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 800px;
  }

  .col {
    display: flex;
    flex: 1 1 250px;
  }

  #ideas {
    padding-bottom: 40px;

    .col {
      text-align: center;
    }

    p {
      margin: 2em auto;
      width: 240px;
    }
  }

  .idea_1 {
    span {
      color: #1b75bb;
      font-weight: 700;
    }
  }

  .idea_2 {
    span {
      color: #be2337;
      font-weight: 700;
    }
  }

  .idea_3 {
    span {
      color: #00a550;
      font-weight: 700;
    }
  }

  #design,
  #strategy {
    background-color: hsl(240, 5%, 91%);
  }

  & {
    .mfp-hide {
      display: none;
    }

    .story {
      background-color: #fff;
      position: relative;
      width: 100%;

      .hand {
        bottom: 0;
        display: none;
        height: 510px;
        left: 36%;
        opacity: 0;
        position: absolute;
        z-index: 3;
      }
    }

    .scrollmagic-pin-spacer {
      .story {
        z-index: 2;
      }
    }

    .story-background {
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 75vw;
    }

    .story-timeline {
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 133vw;
    }

    .story1,
    .story6 {
      .hand {
        opacity: 1;
      }
    }

    .story {
      .hand {
        img {
          height: 100%;
        }
      }
    }

    .story1 {
      .story-background {
        background-image: url(${BgStory1});
      }

      .story-timeline {
        background-image: url(${BgTimeline1});
      }
    }

    .story2 {
      .story-background {
        background-image: url(${BgStory2});
      }

      .story-timeline {
        background-image: url(${BgTimeline2});
      }
    }

    .story3 {
      .story-background {
        background-image: url(${BgStory3});
      }

      .story-timeline {
        background-image: url(${BgTimeline3});
      }
    }

    .story4 {
      .story-background {
        background-image: url(${BgStory4});
      }

      .story-timeline {
        background-image: url(${BgTimeline4});
      }
    }

    .story5 {
      .story-background {
        background-image: url(${BgStory5});
      }

      .story-timeline {
        background-image: url(${BgTimeline5});
      }
    }

    .story6 {
      .story-background {
        background-image: url(${BgStory6});
      }

      .story-timeline {
        background-image: url(${BgTimeline6});
      }
    }
  }

  .story {
    .play-video {
      left: 50%;
      position: absolute;
      margin-left: -65.5px;
      top: 72%;

      img {
        height: 131px;
        width: 131px;
      }
    }
  }

  .story-video {
    display: block;
    max-width: 1280px;
    width: 100%;
  }
  @media screen and (min-width: 645px) {
    & {
      .story {
        /* overflow: hidden; */
        display: flex;
        justify-content: space-between;

        .play-video {
          left: 80px;
          margin-top: -67px;
          margin-left: 0;
          top: 50%;
        }
      }

      .story-background {
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
        /* float: left; */
        min-height: 600px;
        width: 50%;
      }

      .story-timeline {
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        /* float: right; */
        min-height: 600px;
        width: 50%;
      }
    }
  }
  @media screen and (min-width: 645px) {
    & {
      .story {
        .hand {
          display: block;
        }
      }
    }
  }

  #demo {
    height: 220px;
    background-color: #838388;
  }

  .link {
    font-size: 1.8rem;
    text-align: center;
    padding-bottom: 40px;

    a {
      color: #fff;

      &:hover {
        color: #0b6077;
      }
    }
  }

  .blue {
    background-color: #0298cd;
    padding-bottom: 80px;
    width: 100%;
    height: 280px;

    p {
      color: #fff;
      font-size: 1.8rem;
      font-weight: 100;
      margin: 0;
    }
  }

  /* Magnific Popup */

  /* Styles for dialog window */
  .zoom-anim-dialog {
    background: white;
    text-align: left;
    max-height: 100%;
    max-width: 1280px;
    margin: 40px auto;
    position: relative;
    width: 90%;
  }

  /**
 * Fade-zoom animation for first dialog
 */

  .my-mfp-zoom-in {
    /* start state */
    .zoom-anim-dialog {
      opacity: 0;
      transition: all 0.2s ease-in-out;
      transform: scale(0.8);
    }

    /* animate in */
    &.mfp-ready {
      .zoom-anim-dialog {
        opacity: 1;
        transform: scale(1);
      }
    }

    /* animate out */
    &.mfp-removing {
      .zoom-anim-dialog {
        transform: scale(0.8);
        opacity: 0;
      }
    }

    /* Dark overlay, start state */
    &.mfp-bg {
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }

    /* animate in */
    &.mfp-ready.mfp-bg {
      opacity: 0.8;
    }

    /* animate out */
    &.mfp-removing.mfp-bg {
      opacity: 0;
    }
  }
`

const demoStyles = css`
  background-color: hsl(192, 5%, 82%);
  padding: 80px 0;
  position: relative;
  text-align: center;

  p {
    margin: 0;

    ~ p {
      margin-top: 1rem;
    }
  }
`

const demoLinkStyles = css`
  border-bottom: 3px solid #333;
  color: #333;
  font-size: 1.8rem;
  text-decoration: none;
  text-transform: uppercase;
`

const jumpLinkStyles = css`
  background-color: hsl(0, 0%, 20%);
  border-radius: 50%;
  display: block;
  left: calc(50% - 24px);
  padding: 10px;
  position: absolute;
  top: -24px;

  img {
    display: block;
    height: 32px;
    width: 32px;
  }
`

export { timelineStyles, demoStyles, demoLinkStyles, jumpLinkStyles }
