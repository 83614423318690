import React, { useState, useRef, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'
import { PhotoSwipe } from 'react-photoswipe'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { timelineStyles, demoStyles, demoLinkStyles, jumpLinkStyles } from './styles/timeline-styles'
import UpArrow from '../images/up_arrow.svg'

import { setUpFiles, getImage, getVideo } from '../utils/getMedia'

const propTypes = {
  data: PropTypes.object.isRequired,
}

const demoProptypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  linkType: PropTypes.string,
}

const Demo = props => {
  const { url, title, linkType } = props
  return (
    <p>
      <a href={url} css={demoLinkStyles}>
        View {title} {linkType}
      </a>
    </p>
  )
}

Demo.propTypes = demoProptypes

const timelineTemplate = props => {
  const project = props.data.contentfulProject
  const { projectDetails } = project
  const staticFiles = [...props.data.images.nodes, ...props.data.videos.nodes]
  setUpFiles(staticFiles)

  // Photoswipe Settings
  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(null)
  const options = {
    history: false,
    index: index || 0,
  }

  const openPhotoSwipe = (e, i) => {
    e.preventDefault()
    setIsOpen(true)
    setIndex(i)
  }

  const handleClose = () => {
    setIsOpen(false)
    setIndex(null)
  }

  // Slider Settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const story1Ref = useRef(null)

  const slideshowItems = [
    {
      src: getImage('artboards_1').publicURL,
      w: 850,
      h: 942,
      title: '',
    },
    {
      src: getImage('artboards_3').publicURL,
      w: 2006,
      h: 1128,
      title: '',
    },
    {
      src: getImage('artboards_4').publicURL,
      w: 2006,
      h: 1128,
      title: '',
    },
    {
      src: getImage('artboards_5').publicURL,
      w: 2006,
      h: 1128,
      title: '',
    },
    {
      src: getImage('artboards_2').publicURL,
      w: 850,
      h: 942,
      title: '',
    },
    {
      src: getImage('artboards_6').publicURL,
      w: 2006,
      h: 1128,
      title: '',
    },
    {
      src: getImage('artboards_7').publicURL,
      w: 2006,
      h: 1128,
      title: '',
    },
    {
      src: getImage('artboards_8').publicURL,
      w: 2006,
      h: 1128,
      title: '',
    },
    {
      src: getImage('artboards_9').publicURL,
      w: 2006,
      h: 1128,
      title: '',
    },
  ]

  return (
    <Layout unstyled>
      <SEO title={project.title} />
      <div css={timelineStyles} id="field_notes" className="scrolling field_notes">
        <section className="main_header">
          <div className="header_text">
            <GatsbyImage image={getImage('front_logo').childImageSharp.gatsbyImageData} alt="" />
            <span>A new solution for officers</span>
            <span>on field consolidates</span>
            <div className="rw-words rw-words-1">
              <span>
                <i>personal note-taking</i>
              </span>
              <span>
                <i>shift logging</i>
              </span>
              <span>
                <i>incident-related collaboration</i>
              </span>
              <span>
                <i>dynamic reporting processes</i>
              </span>
            </div>
          </div>
          <div className="phone">
            <img src={getImage('front_phone1').publicURL} alt="" width="301" />
          </div>
        </section>

        <section id="how-we-start" className="">
          <header className="title">
            <h1>How We Start</h1>
          </header>
          <p>
            Timeline in an original idea came from customer research. We know information collection and management are high importance in
            public safety operations. But we want to understand detail use case of how the officers collect information, and what
            information they have to capture during their whole shift.
          </p>
          <p>
            Based on the Lean UX research with NY/NJ PD, we realized, there is a huge issue for officer to take notes, document their
            activity. The issues are including,
          </p>
          <ul>
            <li>labor Intensiveness</li>
            <li>accessibility collaborative limitation</li>
            <li>huge amounts of paperwork(storage, space, physicality)</li>
          </ul>
        </section>

        <section id="what-officers-currently-use" className="">
          <header className="title">
            <h1>What Officers Currently Use</h1>
          </header>
          <div className="forms">
            <GatsbyImage image={getImage('forms1').childImageSharp.gatsbyImageData} alt="" />
          </div>
        </section>

        <section id="purpose-build-notes-app" className="grey scene">
          <header className="title title-white">
            <h1>Purpose-built Notes App</h1>
          </header>
          <div className="columns">
            <div className="col">
              <ul>
                <li>One, unified experience that stitches together pre-, during- and post-incident information collection</li>
                <li>Collaboration-focused</li>
              </ul>
            </div>
            <div className="col">
              <ul>
                <li>Enhanced information quality and fidelity, with evidentiary-grade data integrity</li>
                <li>Reduced redundancy and workload</li>
              </ul>
            </div>
          </div>
          <div className="initial">
            <video className="video initial-video" poster={getImage('initial_video1').publicURL} controls>
              <source src={getVideo('initial').publicURL} type="video/mp4" />
            </video>
          </div>
        </section>

        <section id="ideas" className="">
          <header className="title">
            <h1>Design Process</h1>
          </header>
          <Slider {...settings}>
            {slideshowItems.map((item, i) => (
              <Fragment key={item.title}>
                <a href="#" onClick={e => openPhotoSwipe(e, i)}>
                  <img src={item.src} alt={item.title} />
                </a>
              </Fragment>
            ))}
          </Slider>
          <PhotoSwipe isOpen={isOpen} items={slideshowItems} options={options} onClose={handleClose} />
          <div className="columns">
            <div className="col idea_1">
              <p>
                <img src={getImage('ideas_11').publicURL} alt="" width="163" />
                <br />
                <br />
                <span>The main timeline</span> is user’s personal shift log. It records what user did during the shift.
              </p>
            </div>
            <div className="col idea_2">
              <p>
                <img src={getImage('ideas_21').publicURL} alt="" width="163" />
                <br />
                <br />
                <span>The incident timeline</span> is a collaboration pool, collecting all the incident information from different users.
              </p>
            </div>
            <div className="col idea_3">
              <p>
                <img src={getImage('ideas_31').publicURL} alt="" width="163" />
                <br />
                <br />
                <span>Task is a to-do list</span>, it can be self-created to remind you during the shift. Finished tasks will be added into
                the shift timeline.
              </p>
            </div>
          </div>
        </section>

        <section id="strategy" className="full-screen">
          <div className="side-text">
            <header className="title title-side-left">
              <h1>Strategy Objective</h1>
            </header>
            <p>
              Envision a new timeline-based experience to officers on field, that consolidates personal note-taking and shift logging, while
              supporting incident-related collaboration and dynamic reporting processes.
            </p>
          </div>
          <div className="side-img side-img-right">
            <img src={getImage('side_21').publicURL} alt="" />
          </div>
        </section>

        <section id="timeline" className="forms">
          <img src={getImage('lens').publicURL} alt="" />
        </section>

        <section id="stories" className="stories">
          <Controller>
            <Scene pin="#hand1" pinSettings={{ pushFollowers: false }} offset="100" duration="600">
              <section className="story story1" ref={story1Ref}>
                <div className="story-background"></div>
                <div className="story-timeline"></div>
                <a href="#video1" className="play-video popup-with-zoom-anim">
                  <img src={getImage('play_btn').publicURL} alt="" />
                </a>
                <div id="video1" className="zoom-anim-dialog mfp-hide">
                  <video className="video story-video" controls>
                    <source src={getVideo('FTS_1_in_precinct').publicURL} type="video/mp4" />
                  </video>
                </div>
                <div id="hand1" className="hand">
                  <img src={getImage('hand_11').publicURL} alt="" />
                </div>
              </section>
            </Scene>
            <Scene pin="#hand2" offset="100" duration="600">
              <section className="story story2">
                <div className="story-background"></div>
                <div className="story-timeline"></div>
                <a href="#video2" className="play-video popup-with-zoom-anim">
                  <img src={getImage('play_btn').publicURL} alt="" />
                </a>
                <div id="video2" className="zoom-anim-dialog mfp-hide">
                  <video className="video story-video" controls>
                    <source src={getVideo('FTS_2_on_patrol').publicURL} type="video/mp4" />
                  </video>
                </div>
                <Tween to={{ opacity: 1 }}>
                  <div id="hand2" className="hand">
                    <img src={getImage('hand_21').publicURL} alt="" />
                  </div>
                </Tween>
              </section>
            </Scene>
            <Scene pin="#hand3" offset="100" duration="600">
              <section className="story story3">
                <div className="story-background"></div>
                <div className="story-timeline"></div>
                <a href="#video3" className="play-video popup-with-zoom-anim">
                  <img src={getImage('play_btn').publicURL} alt="" />
                </a>
                <div id="video3" className="zoom-anim-dialog mfp-hide">
                  <video className="video story-video" controls>
                    <source src={getVideo('FTS_3_at_incident').publicURL} type="video/mp4" />
                  </video>
                </div>
                <Tween to={{ opacity: 1 }}>
                  <div id="hand3" className="hand">
                    <img src={getImage('hand_31').publicURL} alt="" />
                  </div>
                </Tween>
              </section>
            </Scene>
            <Scene pin="#hand4" offset="100" duration="600">
              <section className="story story4">
                <div className="story-background"></div>
                <div className="story-timeline"></div>
                <a href="#video4" className="play-video popup-with-zoom-anim">
                  <img src={getImage('play_btn').publicURL} alt="" />
                </a>
                <div id="video4" className="zoom-anim-dialog mfp-hide">
                  <video className="video story-video" controls>
                    <source src={getVideo('FTS_4_back').publicURL} type="video/mp4" />
                  </video>
                </div>
                <Tween to={{ opacity: 1 }}>
                  <div id="hand4" className="hand">
                    <img src={getImage('hand_41').publicURL} alt="" />
                  </div>
                </Tween>
              </section>
            </Scene>
            <Scene pin={false}>
              <section className="story story5">
                <div className="story-background"></div>
                <div className="story-timeline"></div>
                <div id="hand5" className="hand">
                  <img src={getImage('hand_51').publicURL} alt="" />
                </div>
              </section>
            </Scene>
          </Controller>
          <section className="story story6">
            <div className="story-background"></div>
            <div className="story-timeline"></div>
          </section>
        </section>

        <section css={demoStyles}>
          <a href="#" css={jumpLinkStyles}>
            <img src={UpArrow} alt="Jump to the top" />
          </a>
          {projectDetails &&
            projectDetails.map(contentType => {
              if (contentType.__typename === 'ContentfulProjectDemo') {
                return <Demo key={contentType.id} url={contentType.url} title={contentType.title} linkType={contentType.linkType} />
              }
            })}
        </section>
      </div>
    </Layout>
  )
}

timelineTemplate.propTypes = propTypes

export default timelineTemplate

export const pageQuery = graphql`
  query($id: String!) {
    images: allFile(filter: { relativeDirectory: { eq: "field_notes" }, sourceInstanceName: { eq: "images" } }) {
      nodes {
        name
        publicURL
        sourceInstanceName
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    videos: allFile(filter: { relativeDirectory: { eq: "field_notes" }, sourceInstanceName: { eq: "videos" } }) {
      nodes {
        name
        publicURL
        sourceInstanceName
      }
    }
    contentfulProject(id: { eq: $id }) {
      id
      title
      description {
        description
      }
    }
  }
`
